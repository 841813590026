import {useParams} from "react-router";
import {EventParticipantsOverview} from "./EventParticipantsOverview";
import React, {ChangeEvent, Component} from "react";
import localizedTexts from "./texts/EventParticipantsResult.texts";
import ApiClientFactory from "./models/ApiClientFactory";
import {
    EventRegistratioInformation, Exercise,
    ParticipantWithResults, ShootingProgramDefinition,
    ShootingProgramDefinitionRegistration, ShootingProgramSequence, Weapon
} from "./models/Models";

function EventParticipantsResultWrapper(){
    let {eventId, participantnumber} = useParams();
    return <EventParticipantsResult eventId={eventId} participantNumber={participantnumber} />
}

export class EventParticipantsResult extends Component<EventParticipantsResultProps, EventParticipantsResultState>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventParticipantsResultProps) {
        super(props);

        this.state = {
            eventRegistrationInformation: undefined,
            participant: undefined,
            allWeapons: [],
            shootingProgramDefinitions: {},
            selectedExerciseId: undefined,
            isLoading: true,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            var infos = await this.apiClient.api.eventsEventInformationRegistrationinformationDetail(this.props.eventId ?? "");
            if (infos.ok) {
                var weapons: any = {};
                var shootingProgramDefinitions: any = {};
                if (infos.data.disciplines?.length && infos.data.disciplines?.length > 0) {
                    infos.data.disciplines.forEach(d => {
                        d.shootingProgramDefinitions?.forEach(s => {
                            var shootingProgramDefinitionId = `${s.id}`;
                            shootingProgramDefinitions[shootingProgramDefinitionId] = s;
                        })
                        d.fields?.forEach(f => {
                            f.weapons?.forEach(w => {
                                var weaponId = `${w.id}`;
                                weapons[weaponId] = w;
                            })
                        })
                    });
                }

                this.setState({
                    eventRegistrationInformation: infos.data,
                    allWeapons: weapons,
                    shootingProgramDefinitions: shootingProgramDefinitions,
                    isLoading: false
                });

                if (this.props.participantNumber) {
                    this.getParticipant(this.props.participantNumber);
                }
            }
        } catch (e: any) {

        }
    }

    handleSearchFieldChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        var newValue = evt.currentTarget.value;
        if (newValue.length < 10 || !this.getParticipant(evt.currentTarget.value)){
            this.setState({participant: {
                participantNumber: newValue
                }})
        }
    }

    getParticipant = async (participantNumber: string):Promise<boolean> => {

        var result = undefined;
        try {
            if (participantNumber.length == 10) {
                result = await this.apiClient.api.eventsParticipantsWithResultsDetail(participantNumber, this.props.eventId ?? "");
            }
        } catch (e) {
        }
        if (result && result.ok){
            this.setState({participant: result.data});
        }else{
            return false;
        }

        return true;
    }

    getShooterInfo(){
        return <div>
            <div className={"row"}>
                <div className="form-group col-md-6">
                    <label htmlFor="shooternumber">{localizedTexts.labelBarcode}</label>
                    <input type="text" value={this.state.participant?.participantNumber ?? ""} name="shooternumber"
                           onChange={this.handleSearchFieldChange} className="form-control"
                           id="shooternumber" placeholder="" aria-describedby="shooternumber"
                           autoFocus={true}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <h3>{localizedTexts.labelShooter}</h3>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelPersonNumber}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.personNumber}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelLicenseNumber}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.licenseNumber}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelLastName}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.lastName}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelFirstName}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.firstName}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelCity}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {this.state.participant?.person?.city}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelBirthdate}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {this.state.participant?.person?.birthday}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelOrganization}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {this.state.participant?.organization?.name} {this.state.participant?.organization?.city}</span>
                </div>
            </div>
        </div>
    }

    getShootingPrograms() {
        return <div>
            <table className={"table table-striped"}>
                <tbody>
                {
                    this.state.participant?.exercises
                        ?.filter(s => !this.state.shootingProgramDefinitions[`${s.shootingProgramDefinitionId}`]?.isCalculated)
                        ?.sort((a, b) => this.state.shootingProgramDefinitions[`${a.shootingProgramDefinitionId}`]?.visualOrder! - this.state.shootingProgramDefinitions[`${b.shootingProgramDefinitionId}`]?.visualOrder!)
                        .map((e) => {
                            var shootingProgramDefinition: ShootingProgramDefinition = this.state.shootingProgramDefinitions[`${e.shootingProgramDefinitionId}`];
                            var maxResult = 0;
                            shootingProgramDefinition.shootingProgramSequences?.forEach(s => {
                                if(s.firekindId! > 19){
                                    maxResult += s.shotCount! * s.maxShotvalue!;
                                }
                            })

                        return <tr key={e.id}>
                            <td>
                                {shootingProgramDefinition.name}
                            </td>
                            <td>
                                {
                                    shootingProgramDefinition.shotsRequired
                                        ? <button className={"btn btn-outline-primary"} data-bs-toggle="modal"
                                                  data-bs-target="#enterShotsModal" onClick={() => {
                                            this.setState({selectedExerciseId: e.id});

                                        }}>{localizedTexts.buttonEnterResult}</button>
                                        : ""
                                }
                            </td>
                            <td>
                                {
                                    shootingProgramDefinition.isBestShotRequired ? <div><label>{localizedTexts.labelBestShot}:&nbsp;</label>
                                        <input
                                            type={"number"}
                                            disabled={shootingProgramDefinition.shotsRequired}
                                            value={e.bestShotValue ?? 0}
                                            className={"form-field"}
                                            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                var participant = this.state.participant;
                                                var toUpdateExercise = participant?.exercises?.find(pe => pe.id == e.id)!;
                                                if (evt.currentTarget.valueAsNumber <= 100){
                                                    toUpdateExercise.bestShotValue = evt.currentTarget.valueAsNumber;
                                                }
                                                this.setState({participant: participant});
                                            }}
                                        /></div> : <div />
                                }
                                {
                                    shootingProgramDefinition.innerTenCountRequired ? <div><label>{localizedTexts.labelInnerTenCount}:&nbsp;</label>
                                        <input
                                            type={"number"}
                                            disabled={shootingProgramDefinition.shotsRequired}
                                            value={e.innerTenCount ?? 0}
                                            className={"form-field"}
                                            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                var participant = this.state.participant;
                                                var toUpdateExercise = participant?.exercises?.find(pe => pe.id == e.id)!;
                                                if (evt.currentTarget.valueAsNumber <= 100){
                                                    toUpdateExercise.innerTenCount = evt.currentTarget.valueAsNumber;
                                                }
                                                this.setState({participant: participant});
                                            }}
                                        /></div> : <div />
                                }
                            </td>
                            <td>
                                <label>{localizedTexts.labelResult}:&nbsp;</label>
                                <input
                                    type={"number"}
                                    disabled={shootingProgramDefinition.shotsRequired}
                                    value={e.result ?? 0}
                                    max={maxResult}
                                    className={"form-field"}
                                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                        var participant = this.state.participant;
                                        var toUpdateExercise = participant?.exercises?.find(pe => pe.id == e.id)!;
                                        var shotValue = evt.currentTarget.valueAsNumber;
                                        if (shotValue <= maxResult) {
                                            toUpdateExercise.result = evt.currentTarget.valueAsNumber;
                                        }
                                        this.setState({participant: participant});
                                    }}/>
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    }

    handleSaveButton = async () => {
        if (this.state.participant) {
            var participant = this.state.participant;
            participant.person?.organizations?.forEach(o => o.discipline = undefined);
            try {
                var saveResult = await this.apiClient.api.eventsParticipantsUpdateresultsUpdate(this.props.eventId ?? "", participant)
                if (saveResult.ok) {
                    this.handleNextShooter();
                }
            } catch (e: any) {

            }
        }
    }

    handleNextShooter = () => {
        this.setState({selectedExerciseId: undefined, participant: undefined});
        this.reFocusShooterNumber();
    }

    reFocusShooterNumber = () => {
        document.getElementById("shooternumber")?.focus();
    }

    render(){
        if (this.state.isLoading){
            return <div>
                <h1>{localizedTexts.title}</h1>
                <h4>{localizedTexts.textSiteLoading}</h4>
            </div>
        }

        return <div>
            <h1>{localizedTexts.title}</h1>
            <div className={"row p-3 g-3 shadow bg-body rounded"}>
                <div className={"col-md-6"}>
                    {this.getShooterInfo()}
                </div>
                <div className={"col-md-6"}>
                    {this.getShootingPrograms()}
                </div>
            </div>

            <div className={"row p-3 g-3 shadow bg-body rounded"}>
                <div className={"col-md-3"}>
                    <button className={"btn btn-outline-dark registrationButton"} style={{width: "90%"}}
                            onClick={() => this.handleSaveButton()}>{localizedTexts.buttonSave}</button>
                </div>
                <div className={"col-md-3"}>
                    <button className={"btn btn-outline-primary registrationButton"} style={{width: "90%"}}
                            onClick={() => this.handleNextShooter()}>{localizedTexts.buttonNextParticipant}</button>
                </div>
            </div>
            {this.getShotEntryModal()}
        </div>
    }

    getShotEntryModal = () => {
        var exercise = this.state.participant?.exercises?.find(e => e.id == this.state.selectedExerciseId);
            return <div className="modal fade modal-xl" id="enterShotsModal" tabIndex={-1}
                        aria-labelledby="enterShotsModal"
                        aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={"enterShotsModalLabel"}>{localizedTexts.labelEnterShotsModalTitle}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className={"table table-striped table-hover table-responsive-sm"}>
                                <tbody>
                                {this.getShotRowsForModal(exercise)}
                                <tr>
                                    <td colSpan={3}>
                                        <hr/>
                                    </td>
                                </tr>
                                {this.getBestShotInput(exercise)}
                                {this.getInnerTenCountInput(exercise)}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary"
                                    data-bs-dismiss="modal">{localizedTexts.buttonSaveModal}</button>
                        </div>
                    </div>
                </div>
            </div>
    }

    getBestShotInput(exercise: Exercise|undefined) {

        if (!exercise || !this.state.shootingProgramDefinitions[`${exercise.shootingProgramDefinitionId}`].isBestShotRequired){
            return <tr></tr>
        }

        var participant = this.state.participant;
        if (participant) {
            return <tr>
                <td><label>{localizedTexts.labelBestShot}:&nbsp;</label></td>
                <td><input
                    type={"number"}
                    value={exercise.bestShotValue ?? 0}
                    min={0}
                    max={100}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        var toUpdateExercise = participant!.exercises!.find(e => e.id == exercise.id)!;
                        if (evt.currentTarget.valueAsNumber <= 100) {
                            toUpdateExercise.bestShotValue = evt.currentTarget.valueAsNumber;
                        }
                        this.setState({participant: participant});
                    }}
                    className={"form-field"}/></td>
            </tr>
        }

        return <tr></tr>
    }

    getInnerTenCountInput(exercise: Exercise|undefined) {

        if (!exercise || !this.state.shootingProgramDefinitions[`${exercise.shootingProgramDefinitionId}`].innerTenCountRequired){
            return <tr></tr>
        }

        var participant = this.state.participant;
        if (participant) {
            return <tr>
                <td><label>{localizedTexts.labelInnerTenCount}:&nbsp;</label></td>
                <td><input
                    type={"number"}
                    value={exercise.innerTenCount ?? 0}
                    min={0}
                    max={100}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        var toUpdateExercise = participant!.exercises!.find(e => e.id == exercise.id)!;
                        if (evt.currentTarget.valueAsNumber <= 100) {
                            toUpdateExercise.innerTenCount = evt.currentTarget.valueAsNumber;
                        }
                        this.setState({participant: participant});
                    }}
                    className={"form-field"}/></td>
            </tr>
        }

        return <tr></tr>
    }

    getShotRowsForModal(exercise: Exercise | undefined) {
        if (!exercise) {
            return <div>
            </div>
        }
        var participant = this.state.participant;
        var shootingProgramSequences : ShootingProgramSequence[] = this.state.shootingProgramDefinitions[`${exercise.shootingProgramDefinitionId}`]!.shootingProgramSequences!.sort((a:ShootingProgramSequence, b: ShootingProgramSequence) => a.order! - b.order!);

        if (participant) {
            var toUpdateExercise = participant!.exercises!.find(e => e.id == exercise.id)!;

            if (!exercise.shots){
                exercise.shots = [];
            }

            if (exercise.shots.length == 0){
                var currentDate = new Date();
                shootingProgramSequences.filter(sq => sq.firekindId! >= 20).sort(sps => sps.order!).forEach(sps => {
                    Array.from({length: sps.shotCount!}, (value, index) => index+1).forEach((s) => {
                        exercise.shots?.push({
                            shotNumber: s,
                            value: 0,
                            isIllegalShot: false,
                            isDemo: false,
                            isPractice: sps.firekindId == 10,
                            date: `${currentDate.getFullYear()}-${(currentDate.getMonth()+1).toString().padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`,
                            time: `${currentDate.getHours().toString().padStart(2, "0")}:${currentDate.getMinutes().toString().padStart(2, "0")}:${currentDate.getSeconds().toString().padStart(2, "0")}`
                        });
                    })
                })
            }

                return exercise.shots?.filter(s => s.isPractice == false).map((s, idx) => {
                    var firekindText = localizedTexts.labelTrainingShots;
                    var firekindShotNumber = idx + 1;
                    var valueSet = false;
                    var maxShotValue = 10;
                    shootingProgramSequences.filter(sq => sq.firekindId! >= 20).forEach((s: ShootingProgramSequence) => {

                        if (firekindShotNumber - s.shotCount! <= 0 && !valueSet) {
                            maxShotValue = s.maxShotvalue ?? 100;
                            switch (s.firekind) {
                                case "Sighters":
                                    firekindText = localizedTexts.labelTrainingShots;
                                    break;
                                case "ShotByShot":
                                    firekindText = localizedTexts.labelSingleShots;
                                    break;
                                case "Series":
                                    firekindText = localizedTexts.labelSeriesShots;
                                    break;
                            }
                            valueSet = true;
                        } else if (firekindShotNumber > s.shotCount! && !valueSet) {
                            firekindShotNumber = firekindShotNumber - s.shotCount!;
                        }
                    });

                    return <tr key={exercise.id + `${idx}`}>
                        <td>{firekindShotNumber}. {firekindText}:</td>
                        <td><input
                            autoFocus={idx == 0}
                            type={"number"}
                            value={s.value ?? 0}
                            min={0}
                            max={maxShotValue}//should be updated to proper value
                            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                if (maxShotValue >= evt.currentTarget.valueAsNumber) {
                                    toUpdateExercise.shots!.filter(s => s.isPractice == false)[idx].value = evt.currentTarget.valueAsNumber;
                                }

                                var arrayIndex = 0;
                                var result: number = 0;
                                shootingProgramSequences.sort((a: ShootingProgramSequence, b: ShootingProgramSequence) => a.order! - b.order!).forEach((s: ShootingProgramSequence) => {
                                    for (let i = 0; i < s.shotCount!; i++) {
                                        if (s.firekindId && s.firekindId >= 20) {
                                            result += toUpdateExercise.shots!.filter(s => s.isPractice == false)[arrayIndex].value ?? 0;
                                            ++arrayIndex;
                                        }
                                    }
                                })
                                toUpdateExercise.result = result;
                                this.setState({participant: participant});
                            }}/></td>
                    </tr>
                })
        }

        return <div></div>
    }
}

interface EventParticipantsResultState {
    eventRegistrationInformation: EventRegistratioInformation | undefined;
    participant: ParticipantWithResults | undefined;
    allWeapons: any;
    shootingProgramDefinitions: any;
    selectedExerciseId: number | undefined;
    isLoading: boolean;
}

interface EventParticipantsResultProps {
    eventId: string | undefined;
    participantNumber: string | undefined;
}

export default EventParticipantsResultWrapper;